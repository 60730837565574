import { ViteSSG } from 'vite-ssg';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css'; // Import Tailwind CSS
import '@fortawesome/fontawesome-free/css/all.css'; // Import FontAwesome
import { createHead } from '@unhead/vue';

export const createApp = ViteSSG(
  App,
  { routes: router.getRoutes() },
  ({ app, router }) => { // Removed unused variables
    const head = createHead();
    app.use(router);
    app.use(head);
  }
);
