<template>
  <div class="bg-gray-900 text-white min-h-screen">
    <!-- Hero Section -->
    <section class="relative w-full h-96 flex items-center justify-center bg-gray-800">
      <div class="text-center">
        <h1 class="text-4xl font-bold">Looking for Love with Purpose and Passion</h1>
        <p class="mt-4">Join Matt Smith on a Journey of Faith, Friendship, and Forever</p>
        <button @click="scrollToCTA" class="mt-8 px-6 py-3 bg-blue-600 text-white rounded-full">Connect with Matt Today</button>
      </div>
    </section>

    <!-- About Matt Section -->
    <section class="py-12">
      <div class="container mx-auto text-center">
        <h2 class="text-3xl font-bold mb-6">Who is Matt Smith?</h2>
        <p class="mb-6">Matt Smith is a dedicated individual with a love for cats, a strong faith in Jesus, and a successful career in digital marketing. Living in Virginia Beach, VA, he thrives on building meaningful connections and making a positive impact.</p>
        <blockquote class="italic text-gray-400">"I believe in love that is rooted in faith and shared experiences. Let's discover what we can build together."</blockquote>
      </div>
    </section>

    <!-- Interests and Hobbies Section -->
    <section class="py-12 bg-gray-800">
      <div class="container mx-auto text-center">
        <h2 class="text-3xl font-bold mb-6">Things Matt Loves</h2>
        <div class="flex flex-wrap justify-center">
          <div class="w-full md:w-1/3 px-4 mb-6">
            <div class="bg-gray-700 p-6 rounded-lg">
              <i class="fas fa-church text-4xl mb-4"></i>
              <h3 class="text-xl font-semibold mb-2">Faith and Worship</h3>
              <p>Commitment to worship and community activities.</p>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-4 mb-6">
            <div class="bg-gray-700 p-6 rounded-lg">
              <i class="fas fa-cat text-4xl mb-4"></i>
              <h3 class="text-xl font-semibold mb-2">Cats</h3>
              <p>Love for cats and the joy they bring to life.</p>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-4 mb-6">
            <div class="bg-gray-700 p-6 rounded-lg">
              <i class="fas fa-laptop text-4xl mb-4"></i>
              <h3 class="text-xl font-semibold mb-2">Digital Marketing</h3>
              <p>Passion for digital marketing and running Crush Digital.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Contact Matt Section -->
    <section id="contact" class="py-12">
      <div class="container mx-auto text-center">
        <h2 class="text-3xl font-bold mb-6">Ready to Connect?</h2>
        <p class="text-2xl">Text Matt at <a href="sms:+17575306288" class="text-blue-600">757-530-MATT</a></p>
      </div>
    </section>

    <!-- Footer -->
    <footer class="py-6 bg-gray-800 text-white text-center">
      <div class="container mx-auto">
        <p>Follow Matt on</p>
        <div class="flex justify-center space-x-4 mt-2">
          <a :href="socialLinks.instagram" target="_blank" class="text-white"><i class="fab fa-instagram"></i></a>
          <a :href="socialLinks.linkedin" target="_blank" class="text-white"><i class="fab fa-linkedin"></i></a>
          <a :href="socialLinks.facebook" target="_blank" class="text-white"><i class="fab fa-facebook"></i></a>
        </div>
        <p class="mt-4">&copy; 2024 Matt Smith. All Rights Reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      socialLinks: {
        instagram: 'https://www.instagram.com/matt.from.crush/',
        linkedin: 'https://www.linkedin.com/in/mattfromcrush/',
        facebook: 'https://www.facebook.com/mattsmithvb/'
      }
    };
  },
  methods: {
    scrollToCTA() {
      document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    }
  }
};
</script>

<style scoped>
/* Add any additional styling here */
</style>
