<template>
  <div id="app">
    <LandingPage />
  </div>
</template>

<script>
import LandingPage from './components/LandingPage.vue';
import { useHead } from '@unhead/vue';

export default {
  name: 'App',
  components: {
    LandingPage
  },
  setup() {
    useHead({
      title: 'Meet Matt Smith: A Heart for Faith and Love',
      meta: [
        {
          property: 'og:title',
          content: 'Meet Matt Smith: A Heart for Faith and Love'
        },
        {
          property: 'og:image',
          content: '/matt.jpg' // Ensure the path is relative to the root
        }
      ]
    });
  }
};
</script>

<style>
/* Global styles */
body {
  @apply bg-gray-900 text-white;
}
</style>
